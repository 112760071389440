import { useSelector } from 'react-redux';
import { RootState } from '@interfaces/rootState';
import { UserProfilePrimaryPracticeSelectorId } from '@store/selectors/UserProfileSelector';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Surgeon } from '@data/surgeon/Surgeon';
import { ENDPOINT_ALL_SURGEONS } from '@utilities/apiConstants';
import React from 'react';
import SurgeonWithDisplayName from '@data/surgeon/SurgeonWithDisplayName';
import { getFormattedName } from '@utilities/commonFunction';

const useGetSurgeonsById = () => {
	const practiceId = useSelector<RootState, string>(UserProfilePrimaryPracticeSelectorId);
	const surgeons = useCollection<Surgeon>(ENDPOINT_ALL_SURGEONS + practiceId, { shouldDisableLoadIndicator: true });

	return React.useMemo(() => {
		return surgeons.reduce<Record<string, SurgeonWithDisplayName>>((byId, surgeon) => {
			return {
				...byId,
				[surgeon.id]: {
					...surgeon,
					displayName: getFormattedName(surgeon),
				},
			};
		}, {});
	}, [surgeons]);
};

export default useGetSurgeonsById;
