import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { utcToTimezone } from '@utilities/dateUtilities';
import { useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { AuthCommentApi } from '@interfaces/InsuranceAuthStatus/InsuranceAuthorizationApi.types';

type AuthorizationCommentProps = AuthCommentApi;

const useStyles = makeStyles((theme) => ({
	root: {
		'& .MuiTypography-root': {
			fontSize: '1rem',
			lineHeight: '1.5rem',
		},
		'marginBottom': theme.spacing(2),
	},
	headerRow: {
		'& .MuiTypography-root': {
			fontWeight: 600,
		},
		'display': 'flex',
		'flexWrap': 'wrap',
	},
	field: {
		whiteSpace: 'nowrap',
	},
	separator: {
		paddingLeft: theme.spacing(1),
		paddingRight: theme.spacing(1),
	}
}));

const ConditionalFieldWithSeparator: React.FC<{ field?: string }> = ({ field }) => {
	const classes = useStyles();
	return (
		field ? (
			<>
				<Typography className={classes.field}>
					{field}
				</Typography>
				<Typography className={classes.separator}>•</Typography>
			</>
		) : null
	);
};

export const AuthorizationComment: React.FC<AuthorizationCommentProps> = ({ datetime, name, email, phone, message }) => {
	const classes = useStyles();
	const { unit } = useSelector(CaseDetailsInfoSelector);
	const unitInfo = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unit);
	const convertedDateTime = datetime &&
		unitInfo &&
		unitInfo?.hospitalTimeZone &&
		utcToTimezone(datetime, unitInfo.hospitalTimeZone, 'MM/dd/yyyy h:mmaaa');

	return (
		<Box className={classes.root}>
			<Box className={classes.headerRow}>
				<ConditionalFieldWithSeparator field={convertedDateTime} />
				<ConditionalFieldWithSeparator field={name} />
				<ConditionalFieldWithSeparator field={email} />
				<Typography className={classes.field}>
					{phone}
				</Typography>
			</Box>
			<Typography>
				{message}
			</Typography>
		</Box>
	);
};
