import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import {
	AuthorizationComment
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationComment/AuthorizationComment';
import { AuthorizationGridFields } from '@components/CaseDetails/useDetailSectionGrid/useDetailSectionGrid';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	label: {
		fontSize: '12px',
		textTransform: 'uppercase',
		color: '#999',
		fontWeight: 600,
		letterSpacing: '1px',
		marginBottom: theme.spacing(.75)
	},
}));

export const AuthorizationComments: React.FC = () => {
	const classes = useStyles();
	const { insuranceAuthorization } = useSelector(CaseDetailsInfoSelector);
	const { comments } = insuranceAuthorization || {};

	if (!comments) {
		return null;
	}

	return (
		<Box style={{ gridArea: AuthorizationGridFields.AUTH_COMMENTS }}>
			<Typography className={classes.label}>
				Authorization Comments
			</Typography>
			{comments.map((comment) => {
				return (
					<AuthorizationComment
						{...comment}
						key={`${comment.datetime}-${comment.message}`}
					/>
				);
			})}
		</Box>
	);
};
