import React from 'react';
import { SchedulePageProvider } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import MainPageLayoutContainer from '@components/MainPageLayout/presentationalComponents/MainPageLayoutContainer';
import MainPageLayoutHeader from '@components/MainPageLayout/presentationalComponents/MainPageLayoutHeader';
import { Box, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import MainPageLayoutBody from '@components/MainPageLayout/presentationalComponents/MainPageLayoutBody';
import MainPageLayoutSubsection from '@components/MainPageLayout/presentationalComponents/MainPageLayoutSubsection';
import EventLegend from '@components/UnitCalendarPage/EventLegend/EventLegend';
import {
	BlockPageEventConfig,
	BlockPageStyleConfig,
} from '@components/SchedulePage/BlockPageLegend/BlockPageLegendConfiguration';
import ScheduleColumnsContainer from '@components/SchedulePage/ScheduleColumnsContainer/ScheduleColumnsContainer';
import ScheduleLocationSelection from '@components/SchedulePage/ScheduleLocationSelection/ScheduleLocationSelection';
import ScheduleSurgeonMultiSelect from '@components/SchedulePage/ScheduleSurgeonMultiSelect/ScheduleSurgeonMultiSelect';
import { SchedulingPageHeader } from '@components/SchedulePage/SchedulingPageHeader/SchedulingPageHeader';
import usePageEffects from '@utilities/hooks/usePageEffects/usePageEffects';
import { AuthenticatedBanner } from '@components/AuthenticatedBanner/AuthenticatedBanner';
import BoxWithLoader from '@components/Loaders/BoxWithLoader/BoxWithLoader';
import { GET_BLOCKTIME } from '@store/actions/types';

const useStyles = makeStyles((theme) =>
	createStyles({
		legend: {
			boxShadow: 'none',
			paddingLeft: 0,
		},
		subsection: {
			display: 'flex',
			paddingTop: theme.spacing(2),
			paddingBottom: theme.spacing(3),
			gap: theme.spacing(2),
		},
		bannerSpacing: {
			margin: theme.spacing(3, 5, 1, 5),
			width: '100%'
		},
		loaderWrapper: {
			display: 'flex',
			flex: 'auto 1 1',
			position: 'relative',
			maxWidth: '100%',
		},
		loader: {
			zIndex: 1,
		},
	}),
);

const SchedulePage = () => {
	const classes = useStyles();
	usePageEffects('Scheduling Calendar');

	return (
		<SchedulePageProvider>
			<MainPageLayoutContainer>
				<MainPageLayoutHeader>
					<SchedulingPageHeader />
					<Box className={classes.bannerSpacing}>
						<AuthenticatedBanner />
					</Box>
					<MainPageLayoutSubsection className={classes.subsection}>
						<EventLegend
							className={classes.legend}
							config={BlockPageEventConfig}
							styleConfig={BlockPageStyleConfig}
						/>
					</MainPageLayoutSubsection>
					<MainPageLayoutSubsection
						flex="auto"
						justifyContent="end"
						className={classes.subsection}
					>
						<ScheduleSurgeonMultiSelect />
						<ScheduleLocationSelection />
					</MainPageLayoutSubsection>
				</MainPageLayoutHeader>
				<MainPageLayoutBody>
					<BoxWithLoader label={GET_BLOCKTIME} className={classes.loaderWrapper} loaderProps={{ className: classes.loader }}>
						<ScheduleColumnsContainer />
					</BoxWithLoader>
				</MainPageLayoutBody>
			</MainPageLayoutContainer>
		</SchedulePageProvider>
	);
};

export default SchedulePage;
