import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
	AuthorizationActionSelect
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/AuthorizationActionSelect/AuthorizationActionSelect';
import { AuthorizationGridFields } from '@components/CaseDetails/useDetailSectionGrid/useDetailSectionGrid';
import {
	useGetAuthActionsByFlag
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/hooks/useGetAuthActionsByFlag';
import { useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import {
	AuthorizationActionSubmit
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/AuthorizationActionSubmit/AuthorizationActionSubmit';
import { useForm } from 'react-hook-form';
import {
	AuthorizationActionSelectApi
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/AuthorizationActionSelectApi';
import { FormMetaProvider } from '@store/context/FormMetaContext';
import { AuthorizationActionSchema } from '@utilities/Validation/validationSchema';
import useHasPermission from '@utilities/hooks/useHasPermission/useHasPermission';
import { PermittedAction } from '@utilities/PermittedAction';

const useStyles = makeStyles(() => ({
	authActions: {
		'& .MuiFormControl-root': {
			width: '90%',
		}
	}
}));

const OFFICE_ACTION_NEEDED = 'OFFICE_ACTION_NEEDED';

export const AuthorizationActionTaken: React.FC  = () => {
	const classes = useStyles();

	const officeActionNeeded = useGetAuthActionsByFlag(OFFICE_ACTION_NEEDED);
	const { insuranceAuthorization } = useSelector(CaseDetailsInfoSelector);
	const { status } = insuranceAuthorization || {};

	const formMethods = useForm<AuthorizationActionSelectApi>({
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		criteriaMode: 'all',
	});

	const isValidStatus = React.useMemo(() => {
		return status && officeActionNeeded.some(option => option.value === status);
	}, [status, officeActionNeeded]);

	const permissionToViewDropdown = useHasPermission(PermittedAction.SUBMIT_AUTH_ACTION);

	if (!isValidStatus || !permissionToViewDropdown) {
		return null;
	}

	return (
		<FormMetaProvider schema={AuthorizationActionSchema} {...formMethods}>
			<Box style={{ gridArea: AuthorizationGridFields.AUTH_ACTIONS }} className={classes.authActions}>
				<AuthorizationActionSelect />
				<AuthorizationActionSubmit />
			</Box>
		</FormMetaProvider>
	);
};
