import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';
import {
	InsuranceAuthStatusResponse
} from '@interfaces/InsuranceAuthStatus/InsuranceAuthStatusState.types';
import { ENDPOINT_AUTH_STATUS_LABELS, ENDPOINT_UPDATE_AUTH_STATUS } from '@utilities/apiConstants';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import { CacheType } from '@interfaces/CacheType';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { UPDATE_AUTH_STATUS_TYPES, UPDATE_CASE_AUTH_STATUS } from '@store/actions/types';
import { InsuranceAuthStatusActionTypes } from '@interfaces/InsuranceAuthStatus/InsuranceAuthStatusAction.types';
import { Dispatch } from 'redux';
import { ApiActionPayload, ApiResponseType } from '@interfaces/ApiAction/ApiActionPayload';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import ApiRestMethod from '@utilities/apiEndpoints/ApiRestMethod';
import {
	AuthorizationActionSelectApi
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/AuthorizationActionSelectApi';

const updateAuthStatusTypes = ({ data }: ApiResponseType<InsuranceAuthStatusResponse>): InsuranceAuthStatusActionTypes => {
	return {
		type: UPDATE_AUTH_STATUS_TYPES,
		payload: data,
	};
};

export const getInsuranceAuthStatusTypes = () => {
	const onSuccess = (res: ApiResponseType<InsuranceAuthStatusResponse>) => {
		return (dispatch: Dispatch) => {
			dispatch(updateAuthStatusTypes(res));
		};
	};
	return apiAction<undefined, ErrorStatusState, InsuranceAuthStatusResponse>({
		url: ENDPOINT_AUTH_STATUS_LABELS,
		cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
		cachePolicy: CacheType.RETRIEVE_OR_ADD_CACHE,
		includeAccessToken: true,
		label: UPDATE_AUTH_STATUS_TYPES,
		onSuccess,
		apiService: ApiService.SSM,
	});
};

export const updateCaseAuthStatusInStore = (data : AuthorizationActionSelectApi) => {
	return {
		type: UPDATE_CASE_AUTH_STATUS,
		payload: data,
	};
};

export const updateInsuranceAuthStatus = (authId: string, authAction: AuthorizationActionSelectApi, requestOverride: Partial<ApiActionPayload> = {}) => {
	const url = endpointTemplateToUrl(ENDPOINT_UPDATE_AUTH_STATUS, { authorizationId: authId });
	return apiAction({
		url,
		data: authAction,
		method: ApiRestMethod.PUT,
		cachePolicy: CacheType.NO_CACHE,
		includeAccessToken: true,
		shouldDisableErrorHandler: false,
		label: UPDATE_CASE_AUTH_STATUS,
		apiService: ApiService.SSM,
		...requestOverride
	});
};
