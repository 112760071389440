/***
 * Permitted actions used with the useHasPermission hook.
 *
 * Every action must also have an associated list of user roles in PermittedActionToUserRole
 * @enum {string}
 */
export enum PermittedAction {
	ENTER_FIN = 'ENTER_FIN',
	REQUEST_CASE_REVISION = 'REQUEST_CASE_REVISION',
	CONFIRM_CANCEL_CASE = 'CONFIRM_CANCEL_CASE',
	ACKNOWLEDGE_CASE_ALERT = 'ACKNOWLEDGE_CASE_ALERT',
	CREATE_CASE = 'CREATE_CASE',
	MANAGE_CASE = 'MANAGE_CASE',
	MODIFY_TEMPLATE = 'MODIFY_TEMPLATE',
	CONFIRM_CASE_SCHEDULE = 'CONFIRM_CASE_SCHEDULE',
	ACKNOWLEDGE_REVISION_ALERT = 'ACKNOWLEDGE_REVISION_ALERT',
	VIEW_CONDENSED = 'VIEW_CONDENSED',
	CREATE_HOLD_EVENT = 'CREATE_HOLD_EVENT',
	EDIT_INDEX = 'EDIT_INDEX',
	EDIT_INDEX_NO_CONFIRM = 'EDIT_INDEX_NO_CONFIRM',
	VIEW_NAVIGATION_OPTIONS = 'VIEW_NAVIGATION_OPTIONS',
	SUBMIT_AUTH_ACTION = 'SUBMIT_AUTH_ACTION',
}
