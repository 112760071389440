export enum CaseActivityTypeEnum {
	APPROVED_REVISION = 'APPROVED_REVISION',
	ATTACHMENT = 'ATTACHMENT',
	CANCELLED = 'CANCELLED',
	CONFIRMED = 'CONFIRMED',
	DOCUMENT_SCAN_FAILED = 'DOCUMENT_SCAN_FAILED',
	NEEDS_REVISION = 'NEEDS_REVISION',
	PENDING_CANCELLED = 'PENDING_CANCELLED',
	REJECTED_REVISION = 'REJECTED_REVISION',
	REVISED = 'REVISED',
	SUBMITTED = 'SUBMITTED',
	SUBMIT_REVISION = 'SUBMIT_REVISION',
	UPDATE_AUTHORIZATION = 'UPDATE_AUTHORIZATION',
}
