import { useInformationModal } from '../../InformationModal/InformationModal';
import React from 'react';
import DetailSection from '../DetailSection';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandDetailsConfigDescriptorType from './ExpandDetailsConfigDescriptorType';
import { PatInformationDisplay } from '../PatInformationDisplay/PatInformationDisplay';
import { AppointmentRequestDisplay } from '../AppointmentRequestDisplay/AppointmentRequestDisplay';
import { PatientInformationDisplay } from '../PatientInformationDisplay/PatientInformationDisplay';
import { InsuranceInformationDisplay } from '../InsuranceInformationDisplay/InsuranceInformationDisplay';
import { SurgeryInformationDisplay } from '../SurgeryInformationDisplay/SurgeryInformationDisplay';
import { useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { InsuranceInformationProps } from '../InsuranceInformationDisplay/InsuranceInformationProps';
import {
	DocumentInformationDisplay
} from '@components/CaseDetails/DocumentInformationDisplay/DocumentInformationDisplay';
import { isWebRequest } from '@utilities/typeAssertionUtilities';
import { InsuranceApi } from '@interfaces/SurgeryRequest/InsuranceApi';
import {
	AuthorizationInformationDisplay
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationInformationDisplay';

const useStyles = makeStyles({
	title: {
		'&.MuiTypography-h6': {
			fontSize: '1.125rem',
		},
	},
});

const ExpandTitle: React.FC = ({ children }) => {
	const classes = useStyles();
	return <Typography variant="h6" component="h1" className={classes.title}>{children}</Typography>;
};

const useExpandDetailsContent = () => {
	const caseDetails = useSelector(CaseDetailsInfoSelector);
	let primaryInsurance: InsuranceApi[] | undefined;
	let secondaryInsurance: InsuranceApi[] | undefined;
	if (isWebRequest(caseDetails)) {
		primaryInsurance = (caseDetails && caseDetails.insurances) && caseDetails.insurances.filter(insurance => insurance.primary);
		secondaryInsurance = (caseDetails && caseDetails.insurances) && caseDetails.insurances.filter(insurance => !insurance.primary);
	}

	const attachmentsLength = caseDetails?.attachments?.length || 0;

	const EXPAND_DETAILS_MODAL_CONFIG: Record<DetailSection, ExpandDetailsConfigDescriptorType<InsuranceInformationProps>> = React.useMemo(() => ({
		[DetailSection.PAT]: {
			component: PatInformationDisplay,
			title: 'PAT Information',
		},
		[DetailSection.APPOINTMENT]: {
			component: AppointmentRequestDisplay,
			title: 'Appointment Details'
		},
		[DetailSection.PATIENT]: {
			component: PatientInformationDisplay,
			title: 'Patient Information',
		},
		[DetailSection.PRIMARY_INSURANCE]: {
			component: InsuranceInformationDisplay,
			title: 'Insurance Information',
			props: {
				insurance: (primaryInsurance && primaryInsurance[0]) && primaryInsurance[0]
			},
		},
		[DetailSection.SECONDARY_INSURANCE]: {
			component: InsuranceInformationDisplay,
			title: 'Insurance Information',
			props: {
				insurance: (secondaryInsurance && secondaryInsurance[0]) && secondaryInsurance[0]
			},
		},
		[DetailSection.PROCEDURE]: {
			component: SurgeryInformationDisplay,
			title: 'Surgery Information'
		},
		[DetailSection.ATTACHMENTS]: {
			component: DocumentInformationDisplay,
			title: `Documents (${attachmentsLength})`
		},
		[DetailSection.AUTHORIZATION]: {
			component: AuthorizationInformationDisplay,
			title: 'Authorization'
		},
	}), [primaryInsurance, secondaryInsurance, attachmentsLength]);

	const { openModal } = useInformationModal();

	return React.useCallback((section: DetailSection) => {
		const { component: Component, title, props } = EXPAND_DETAILS_MODAL_CONFIG[section];
		openModal(<Component expanded={true} {...props}/>, <ExpandTitle>{title}</ExpandTitle>);
	}, [openModal, EXPAND_DETAILS_MODAL_CONFIG]);
};

export default useExpandDetailsContent;
