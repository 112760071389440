import React from 'react';
import { CaseIdentifierProps } from './CaseIdentifierProps';
import { makeStyles } from '@material-ui/core/styles';
import { Box, createStyles, Divider, Typography } from '@material-ui/core';
import useHasEhrIntegrationAndRole from '../../utilities/hooks/useHasEhrIntegrationAndRole/useHasEhrIntegrationAndRole';
import { HospitalRoles } from '@interfaces/UserProfile/UserRoleEnum';
import {
	useInsuranceAuthStatusConfig
} from '@utilities/hooks/useInsuranceAuthStatusConfig/useInsuranceAuthStatusConfig';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => createStyles({
	root: {
		display: 'flex',
		marginTop: theme.spacing(1),
	},
	caseIdentifier: {
		'marginLeft': theme.spacing(2),
		'&:first-child': {
			marginLeft: theme.spacing(0),
		},
	},
	pipe: {
		marginLeft: theme.spacing(2),
	},
}));

const DEFAULT_VAL = '--';

const CaseIdentifier: React.FC<CaseIdentifierProps> = ({ caseDetails = {} }) => {
	const classes = useStyles();
	let { caseNumber, fin } = caseDetails;
	const { insuranceAuthorization } = caseDetails;
	caseNumber = caseNumber || DEFAULT_VAL;
	fin = fin || DEFAULT_VAL;
	const { label: insuranceAuthStatusLabel } = useInsuranceAuthStatusConfig(insuranceAuthorization?.status) || {};

	const isHospitalUserWithEhrIntegration = useHasEhrIntegrationAndRole(caseDetails, HospitalRoles);

	return (
		<Box data-field="caseIdentifier" className={classNames('case-identifier-group', classes.root)}>
			{isHospitalUserWithEhrIntegration &&
				<>
					<Typography
						className={classes.caseIdentifier}
						data-field="caseNumber"
						variant="caption"
					>
						Case Number: {caseNumber}
					</Typography>
					<Divider orientation="vertical" className={classes.pipe} />
				</>
			}
			<Typography
				className={classes.caseIdentifier}
				data-field="fin"
				variant="caption"
			>
				FIN: {fin}
			</Typography>
			{insuranceAuthStatusLabel &&
				<>
					<Divider orientation="vertical" className={classes.pipe} />
					<Typography
						className={classes.caseIdentifier}
						data-field="caseList-caseCard-authStatus"
						variant="caption"
					>
						Authorization: {insuranceAuthStatusLabel}
					</Typography>
				</>
			}
		</Box>
	);
};

export default CaseIdentifier;
