import { InsuranceAuthStatusState } from '@interfaces/InsuranceAuthStatus/InsuranceAuthStatusState.types';
import { InsuranceAuthStatusActionTypes } from '@interfaces/InsuranceAuthStatus/InsuranceAuthStatusAction.types';
import { UPDATE_AUTH_STATUS_TYPES } from '@store/actions/types';

const initialState: InsuranceAuthStatusState = {
	insuranceAuthStatusTypes: undefined,
};
export const InsuranceAuthStatusReducer = (state = initialState, action: InsuranceAuthStatusActionTypes) => {
	switch (action.type) {
	case UPDATE_AUTH_STATUS_TYPES:
		return {
			insuranceAuthStatusTypes: action.payload,
		};
	}
	return state;
};
