import { SortOption } from '@components/SortingComponent/SortingComponentProps';
import { DropdownOption } from '@definitions/DropdownOption';
import { DEFAULT_CASES_SORT, DEFAULT_PAGE, DEFAULT_SIZE } from '@utilities/apiConstants';
import { OtherFiltersObjectForCheckbox } from '@interfaces/OtherFilterTypes';
import { HospitalRoles, OfficeRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { AppointmentType } from '@data/request/AppointmentType';
import { TimeSpanValidationConfigs } from '@utilities/Validation/TimeSpanValidationUtilities/TimeSpanValidationUtilities';

export const APP_NAME = 'Surgery Scheduling Manager';
export const LOGIN_NOTIFICATION_BANNER_NOT_AFTER = new Date(2022, 10, 11);

export const TIME_ONLY_FORMAT = 'h:mm aa';
export const NEXT_DAY_TIME = '12:00 AM';
export const TIME_12_REGEX = /^(1[0-2]|0?[1-9]):([0-5][0-9]) (AM|PM)$/;
export const TIME_24_REGEX = /^((0[0-9])|(1[0-9])|(2[0-3])):([0-5][0-9])(:([0-5][0-9]))?$/;
export const TIME_12_REGEX_NO_MERIDIEM = /^(1[0-2]|0?[1-9]):([0-5][0-9])$/;

export const DEFAULT_TOAST_SUCCESS_TIMEOUT = 15000;
export const DEFAULT_TOAST_WARNING_TIMEOUT = 2000;
export const DEFAULT_TOAST_ERROR_TIMEOUT = 30000;

export const TEMPLATE_ENTITY_NAME = 'Templates';
export const CASES_ENTITY_NAME = 'Cases';

export const DATE_ONLY_FORMATTER = 'MM/dd/yyyy';

export const MY_FILTERS_SCROLLABLE_AREA = 'myFilters-scrollable-area';
export const SHRINKABLE_HEADER = 'shrinkable-header';
export const SHRINKABLE_HEADER_TEXT = 'shrinkable-header-text';

export const QUEUE_BASE_ROUTE = '/queue/0';

export const MS_IN_DAY = 24 * 60 * 60 * 1000;

export enum MyFiltersFormState {
	NO_CHANGE = 'NO_CHANGE',
	UPDATED = 'UPDATED',
}

export const filterSortOptions: SortOption[] = [
	{ label: 'PROCEDURE DATE' },
	{ value: 'procedureDate:asc', label: 'NEAREST' },
	{ value: 'procedureDate:desc', label: 'FURTHEST' },
	{ label: 'LOCATION' },
	{ value: 'hospital:asc', label: 'LOCATION A-Z' },
	{ value: 'hospital:desc', label: 'LOCATION Z-A' },
	{ label: 'PATIENT NAME' },
	{ value: 'patientFamilyName:asc', label: 'PATIENT A-Z' },
	{ value: 'patientFamilyName:desc', label: 'PATIENT Z-A' },
	{ label: 'DATE CREATED' },
	{ value: 'dateCreated:asc', label: 'OLDEST' },
	{ value: 'dateCreated:desc', label: 'NEWEST' },
];

export const PROCEDURE_DATE_OPTIONS: DropdownOption[] = [
	{ value: 'all', label: 'ALL' },
	{ value: 'historical', label: 'HISTORICAL' },
	{ value: 'upcoming', label: 'UPCOMING' },
	{ value: 'today', label: 'TODAY' },
	{ value: 'tomorrow', label: 'TOMORROW' },
	{ value: 'next-week', label: 'NEXT WEEK' },
	{ value: 'next-month', label: 'NEXT MONTH' },
	{ value: 'custom', label: 'CUSTOM' },
];

export enum OtherFilterFieldTypes {
	All = 'all',
	STATE = 'state',
	STATUS = 'status',
}

export const otherFiltersArrayOfObjectsForCheckboxes: OtherFiltersObjectForCheckbox[] = [
	{ id: 'all', label: 'all', field: OtherFilterFieldTypes.All },
	{ id: 'BOOKMARKED', label: 'Bookmarked', field: OtherFilterFieldTypes.STATE },
	{ id: 'UNSCHEDULED', label: 'Unscheduled', field: OtherFilterFieldTypes.STATUS },
	{ id: 'SCHEDULED', label: 'Scheduled', field: OtherFilterFieldTypes.STATUS },
	{ id: 'CANCELLED', label: 'Cancelled', field: OtherFilterFieldTypes.STATUS },
	{ id: 'PENDING_CANCELLED', label: 'Pending Cancellation', field: OtherFilterFieldTypes.STATUS },
	{ id: 'NEED_REVISION', label: 'Needs Revision', field: OtherFilterFieldTypes.STATE },
	{ id: 'REVISED', label: 'Revised', field: OtherFilterFieldTypes.STATE },
	{ id: 'WARNING', label: 'Warnings', field: OtherFilterFieldTypes.STATE },
	{
		id: 'EHR_CONFLICT',
		label: 'Data Conflict',
		field: OtherFilterFieldTypes.STATE,
		showForRoles: HospitalRoles,
	},
	{
		id: 'PENDING_LINK',
		label: 'Pending Link',
		field: OtherFilterFieldTypes.STATE,
		showForRoles: HospitalRoles,
	},
	{
		id: 'UPDATED_DATE',
		label: 'Updated Date',
		field: OtherFilterFieldTypes.STATE,
		showForRoles: OfficeRoles,
	},
	{
		id: 'ROBOTIC',
		label: 'Robotic',
		field: OtherFilterFieldTypes.STATE,
		showForRoles: HospitalRoles,
	},
];

/**
 * getOtherFiltersArrayOfValuesForField to get array of IDs for specified field/category from otherFiltersArrayOfObjectsForCheckboxes
 * @param {OtherFilterFieldTypes} category - category/field associated with requested array of Ids
 *
 * @returns string[] an array containing ids for a specified field(category)
 */
const getOtherFiltersArrayOfValuesForField: (category: OtherFilterFieldTypes) => string[] = (
	category,
) => {
	return otherFiltersArrayOfObjectsForCheckboxes.reduce(
		(curResult: string[], curObj: OtherFiltersObjectForCheckbox) => {
			if (curObj.field === category) return [...curResult, curObj.id];
			return curResult;
		},
		[],
	);
};

export const otherFiltersCategoryToIdsMap: Record<OtherFilterFieldTypes, string[]> = {
	[OtherFilterFieldTypes.All]: getOtherFiltersArrayOfValuesForField(OtherFilterFieldTypes.All),
	[OtherFilterFieldTypes.STATE]: getOtherFiltersArrayOfValuesForField(
		OtherFilterFieldTypes.STATE,
	),
	[OtherFilterFieldTypes.STATUS]: getOtherFiltersArrayOfValuesForField(
		OtherFilterFieldTypes.STATUS,
	),
};

/**
 * Function to get mapping where key is Id of OtherFiltersObjectForCheckbox and value is field of OtherFiltersObjectForCheckbox
 * @returns Record<string, OtherFilterFieldTypes> mapping from ids to field(category)
 */
export const otherFiltersIdToCategoryMap = (): Record<string, OtherFilterFieldTypes> => {
	return otherFiltersArrayOfObjectsForCheckboxes.reduce(
		(
			curResult: Record<string, OtherFilterFieldTypes>,
			curObj: OtherFiltersObjectForCheckbox,
		) => {
			curResult[curObj.id] = curObj.field;
			return curResult;
		},
		{},
	);
};

export const defaultFilterStartDate = new Date();
export const defaultFilterFutureDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);

export const defaultFilters = {
	sortModel: DEFAULT_CASES_SORT,
	page: DEFAULT_PAGE,
	size: DEFAULT_SIZE,
	surgeon: [],
	procedureDate: '',
	allOtherFilters: [],
	status: [],
	globalFilter: '',
	state: [],
	from: '',
	to: '',
};

export const START_INDICATOR_STR = '##S##';
export const START_INDICATOR_REGEXP = new RegExp(START_INDICATOR_STR, 'g');
export const END_INDICATOR_STR = '##E##';
export const END_INDICATOR_REGEXP = new RegExp(END_INDICATOR_STR, 'g');

export const ATTACHMENT_REQUEST_TYPES = [AppointmentType.WEB, AppointmentType.DOCUMENT];

export const DATE_RANGE_SELECTION_KEY = 'selection';

export const defaultTimeSpanValidationConfigs: TimeSpanValidationConfigs = {
	ignoreIncrements: false,
};

export const AUTH_ACTION_TAKEN_HELPER_TEXT = 'Steps you took to resolve Peer to Peer or Clinical Needed Status';

export const DAYS_OF_WEEK_NUMBERS = [1, 2, 3, 4, 5];

export const DAYS_OF_WEEK_NAMES = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];
