import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { InsuranceAuthStatusConfig } from '@interfaces/InsuranceAuthStatus/InsuranceAuthStatusState.types';
import { InsuranceAuthStatusTypesSelector } from '@store/selectors/InsuranceAuthStatusTypesSelector';
import { getInsuranceAuthStatusTypes } from '@store/actions/InsuranceAuthStatusActionCreators';

export const useInsuranceAuthStatusConfig = (authStatusEnumValue?: string): InsuranceAuthStatusConfig | undefined => {
	const authStatusConfigTypes = useSelector(InsuranceAuthStatusTypesSelector);
	const dispatch = useDispatch();

	React.useEffect(() => {
		if (authStatusConfigTypes) return;

		dispatch(getInsuranceAuthStatusTypes());
	}, [authStatusConfigTypes, dispatch]);

	return authStatusEnumValue ? authStatusConfigTypes?.[authStatusEnumValue] : undefined;
};
