import React from 'react';
import { useFormContext } from 'react-hook-form';
import CommonRequestForm from '@interfaces/RequestForm/forms/CommonRequestForm';
import { WebRequestFormValidatedState } from '@interfaces/RequestForm/forms/RequestFormValidatedState';
import { useControlModal } from '@utilities/hooks/useControlModal/useControlModal';
import { SURGERY_LOCATION_CHANGED_MODAL } from '@components/Modal/modalConstants';
import useCollection from '@utilities/hooks/useCollection/useCollection';
import { Unit } from '@data/unit/Unit';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { useMultiFormValueSetter } from '@components/RequestForm/utilities/MultiFormAdapter/MultiFormAdapter';
import { useProcedureNameContext } from '@components/RequestForm/ProcedureName/ProcedureNameContext';
import { doesUnitHaveOpenTime } from '@utilities/doesUnitHaveOpenTime';

const FIELD_NAME_LOCATION: keyof CommonRequestForm = 'appointmentLocation';
const FIELD_NAME_PROCEDURES: keyof WebRequestFormValidatedState = 'surgeryProcedures';
const FIELD_NAME_DURATION: keyof WebRequestFormValidatedState = 'duration';
const FIELD_NAME_PROCEDURE_NAME: keyof WebRequestFormValidatedState = 'procedureName';

export const useLocationChangeWarning = () => {
	const [pendingLocationChange, setPendingLocationChange] = React.useState<string | null>(null);

	// Automatically show warning when location change is pending
	useControlModal(SURGERY_LOCATION_CHANGED_MODAL.id, !!pendingLocationChange);

	const units = useCollection<Unit>(ENDPOINT_USER_UNITS);

	const setSelectedUnitId = useMultiFormValueSetter(FIELD_NAME_LOCATION);
	const { getValues, setValue } = useFormContext<WebRequestFormValidatedState>();
	const {
		resetProcedureName,
		setSelectedProcedureName,
	} = useProcedureNameContext();

	const clearProcedure = React.useCallback((newLocation) => {
		// Procedure is specific to location, so reset it when location changes
		resetProcedureName();
		setSelectedProcedureName('', true);
		setValue(FIELD_NAME_PROCEDURE_NAME, '');

		const newUnit = units.find(({ id }) => id === newLocation);

		// Only reset duration when new location can show procedure list
		if (newUnit && doesUnitHaveOpenTime(newUnit)) {
			setValue(FIELD_NAME_DURATION, '');
		}

		// If the unit changes, reset the procedureName and set the appointmentLocation to the new Unit ID
		if (newUnit) {
			setValue(FIELD_NAME_LOCATION, newUnit.id);
		}
	}, [resetProcedureName, setSelectedProcedureName, setValue, units]);

	/**
	 * Abort location change by resetting pending location to null. This will dismiss the warning modal.
	 */
	const cancelChange = React.useCallback(() => {
		setPendingLocationChange(null);
	}, [setPendingLocationChange]);

	/**
	 * Finish location change by:
	 * 	  * set selected unit id to pending location
	 * 	  * reset procedure name
	 * 	  * remove primary procedure cards
	 * 	  * reset duration when changing to a location that can show procedure list
	 *
	 */
	const finishChange = React.useCallback(() => {
		setSelectedUnitId(pendingLocationChange);

		clearProcedure(pendingLocationChange);

		// Primary procedure cards created based on selectedProcedureName, so reset when location changes, clear all procedures
		setValue(FIELD_NAME_PROCEDURES, []);

		// Clear pending location to dismiss warning modal
		setPendingLocationChange(null);
	}, [setSelectedUnitId, pendingLocationChange, clearProcedure, setValue]);

	/**
	 * Start flow to change location
	 *   * Set pending location when primary procedure exists (Show modal)
	 *   * Change location immediately when NO primary procedure exists
	 *
	 * @returns {boolean} - True if location change should happen immediately, false otherwise
	 */
	const checkShouldChangeLocationImmediately = React.useCallback(({ target: { value: newLocation } }: React.ChangeEvent<{ value: unknown }>) => {
		const { surgeryProcedures = [], appointmentLocation } = getValues();
		const hasPrimaryProcedure = surgeryProcedures.some(proc => proc.isPrimaryProcedure);

		// Only warn when primary procedure card exists and location is changing
		const shouldWarn = hasPrimaryProcedure && newLocation !== appointmentLocation;

		if (!shouldWarn || typeof newLocation !== 'string') { // unknown required by MUI select so verify string
			clearProcedure(newLocation); // always clear procedure when changing location
			return true; // true to allow default onChange to set location immediately
		}

		setPendingLocationChange(newLocation);
		return false; // false to prevent default onChange from setting location
	}, [clearProcedure, getValues]);

	return {
		cancelChange,
		checkShouldChangeLocationImmediately,
		finishChange,
	};
};
