import React from 'react';
import { Box, Typography, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BlockColumnHeader from '@components/SchedulePage/BlockColumnHeader/BlockColumnHeader';
import BlockColumnProps from '@components/SchedulePage/BlockColumn/BlockColumnProps';
import BoxScrollItem from '@components/BoxScrollHorizontal/BoxScrollItem/BoxScrollItem';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';
import NonBlockColumnHeader from '@components/SchedulePage/NonBlockColumnHeader/NonBlockColumnHeader';
import BlockEvent from '@components/SchedulePage/BlockEvent/BlockEvent';
import { NonCredentialedMessage } from '../NonCredentialedMessage/NonCredentialedMessage';
import AddBlockRequestButton from '../ColumnHeaderCommon/AddBlockRequestButton/AddBlockRequestButton';

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			border: `1px solid ${theme.palette.grey[500]}`,
			flex: '250px 0 0',
			borderRadius: '4px',
			backgroundColor: theme.palette.grey[50],
			display: 'flex',
			flexDirection: 'column',
			boxShadow: '0px 0px 8px 0px rgba(15, 60, 151, 0.25) inset',
			overflowX: 'hidden',
		},
		eventContainer: {
			overflowY: 'auto',
			paddingTop: theme.spacing(2),
			margin: theme.spacing(0, 1),
		},
		surgeonHeaderContainer: {
			backgroundColor: theme.palette.grey[900],
			borderRadius: '4px 4px 0 0',
		},
		surgeonHeader: {
			display: 'flex',
			flexDirection: 'row',
			padding: theme.spacing(1),
			position: 'relative',
		},
		surgeonName: {
			fontWeight: 600,
			color: theme.palette.common.white,
		},
	}),
);

const BlockColumn: React.FC<BlockColumnProps> = ({ blockInfo }) => {
	const classes = useStyles();

	return (
		<BoxScrollItem className={classes.root} data-field="block-column-container">
			<Box className={classes.surgeonHeaderContainer}>
				<Box className={classes.surgeonHeader}>
					<Typography variant="body1" component="h3" className={classes.surgeonName}>
						{blockInfo.displayName}
					</Typography>
					<AddBlockRequestButton blockInfo={blockInfo} />
				</Box>

				{blockInfo.credentialed && (
					<>
						{blockInfo.scheduleHasBlock ? (
							<BlockColumnHeader blockInfo={blockInfo} />
						) : (
							<NonBlockColumnHeader blockInfo={blockInfo} />
						)}
					</>
				)}
			</Box>

			{blockInfo.credentialed ? (
				<BoxFancyScroll className={classes.eventContainer}>
					{blockInfo.events.map((e) => (
						<BlockEvent key={e.id} eventInfo={e} />
					))}
				</BoxFancyScroll>
			) : (
				<NonCredentialedMessage />
			)}
		</BoxScrollItem>
	);
};

export default BlockColumn;
