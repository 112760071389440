import {
	CANCEL_CASE,
	CLEAR_CASE_DETAILS,
	CLEAR_CASE_LOCK_STATUS,
	DELETE_IN_USE_STATUS,
	DELETE_IN_USE_STATUS_SUCCESS,
	EMPTY_ACTION,
	GET_CASE_DETAILS,
	GET_CASE_LOCK_STATUS,
	LOCK_CASE,
	UNLOCK_CASE,
	UPDATE_CASE_ACCESSORS,
	UPDATE_CASE_DETAILS,
	UPDATE_CASE_DETAILS_ERROR,
	UPDATE_CASE_LOCK_STATUS
} from '@store/actions/types';
import errorToString from '@utilities/errorToString';
import { ApiActionRequest } from '@interfaces/ApiAction/ApiAction';
import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { CaseDetailsAction } from '@interfaces/CaseDetails/CaseDetailsAction';
import { ApiActionPayload, ApiResponseType } from '@interfaces/ApiAction/ApiActionPayload';
import {
	BOOKMARK_CASE,
	ENDPOINT_CANCEL_SURGERY,
	ENDPOINT_DELETE_CASE_IN_USE_STATUS,
	ENDPOINT_LOCK_CASE,
	ENDPOINT_LOCK_CASE_STATUS,
	ENDPOINT_UNLOCK_CASE, ENDPOINT_UPDATE_CASE_IN_USE_STATUS,
	SURGERY_API_ENDPOINT
} from '@utilities/apiConstants';
import { SurgeryRequestGet } from '@interfaces/SurgeryRequest/SurgeryRequestGet';
import { Dispatch } from 'redux';
import { CaseLockDescriptor } from '@interfaces/CaseDetails/CaseLockDescriptor';
import { SurgeryRequestAccessor } from '@interfaces/SurgeryRequest/SurgeryRequestAccessor';
import endpointTemplateToUrl from '@utilities/endpointTemplateToUrl';
import { AxiosError, AxiosResponse } from 'axios';
import { defaultCallback } from '@utilities/defaultCallback';
import NoParamNoReturnCallback from '@interfaces/NoParamNoReturnCallback';
import { LockStatus } from '@interfaces/LockStatus';
import { ErrorStatusState } from '@interfaces/ErrorStatus/ErrorStatusState';

const GUID_MATCH = '[a-g0-9]+';
const GUID_MATCH_SELECTOR = `(${GUID_MATCH})`;
const  getCaseIdParserForTemplateUrl = (templateUrl: string) => {
	const REQUEST_URL_REGEX = new RegExp(endpointTemplateToUrl(templateUrl, { requestId: GUID_MATCH_SELECTOR, userId: GUID_MATCH }), 'i');
	return (url: string | undefined) => {
		const [, caseId] = url?.match(REQUEST_URL_REGEX) || [];
		return caseId;
	};
};

export function updateCaseDetails({ data }: ApiResponseType<SurgeryRequestGet>): CaseDetailsAction {
	return {
		type: UPDATE_CASE_DETAILS,
		payload: data
	};
}

function updateCaseDetailsError(error: unknown): CaseDetailsAction {
	return {
		type: UPDATE_CASE_DETAILS_ERROR,
		error: errorToString(error)
	};
}

const updateAccessorUrlParser = getCaseIdParserForTemplateUrl(ENDPOINT_UPDATE_CASE_IN_USE_STATUS);
export function updateAccessorsAction({ config: { url }, data }: AxiosResponse<SurgeryRequestAccessor[]>): CaseDetailsAction {
	const caseId = updateAccessorUrlParser(url);
	return {
		type: UPDATE_CASE_ACCESSORS,
		payload: {
			accessors: data,
			caseId
		},
	};
}

function clearCaseLockStatus(): CaseDetailsAction {
	return {
		type: CLEAR_CASE_LOCK_STATUS
	};
}

export function updateCaseLockStatus({ data }: ApiResponseType<CaseLockDescriptor>): CaseDetailsAction {
	return {
		type: UPDATE_CASE_LOCK_STATUS,
		payload: data
	};
}

export function getCaseDetails(id: string, apiRequestOverrides?: Partial<ApiActionPayload<SurgeryRequestGet>>): ApiActionRequest<SurgeryRequestGet> {
	function handleOnSuccess(response: ApiResponseType<SurgeryRequestGet>) {
		return (dispatch: Dispatch) => {
			dispatch(updateCaseDetails(response));
			if (apiRequestOverrides?.onSuccess) {
				void apiRequestOverrides?.onSuccess(response);
			}
		};
	}
	return apiAction<SurgeryRequestGet>({
		url: SURGERY_API_ENDPOINT + id,
		method: 'GET',
		label: GET_CASE_DETAILS,
		includeAccessToken: true,
		onFailure: updateCaseDetailsError,
		...apiRequestOverrides,
		onSuccess: handleOnSuccess,
	});
}

export function cancelCase (
	id: string,
	message: string,
	overrides?: Partial<ApiActionPayload<{ message: string }, ErrorStatusState, SurgeryRequestGet>>
): ApiActionRequest<{ message: string }, ErrorStatusState, SurgeryRequestGet> {
	const cancelApiEndpoint = endpointTemplateToUrl(ENDPOINT_CANCEL_SURGERY, { requestId: id });
	return apiAction<{ message: string }, ErrorStatusState, SurgeryRequestGet>({
		url: cancelApiEndpoint,
		method: 'PUT',
		label: CANCEL_CASE,
		includeAccessToken: true,
		data: {
			message
		},
		onSuccess: updateCaseDetails,
		onFailure: updateCaseDetailsError,
		...overrides
	});
}

const DEFAULT_OPTIONS = { onSuccess: defaultCallback };

export function lockCase(id: string, options: Partial<ApiActionPayload> = DEFAULT_OPTIONS): ApiActionRequest<unknown, ErrorStatusState, LockStatus> {
	const lockCaseEndPoint = ENDPOINT_LOCK_CASE
		.replace('{requestId}', id);

	const optionsWithDefault = {
		...DEFAULT_OPTIONS,
		...options,
	};

	return apiAction<unknown, ErrorStatusState, LockStatus>({
		url: lockCaseEndPoint,
		method: 'POST',
		label: LOCK_CASE,
		includeAccessToken: true,
		asDataOrParams: 'params',
		data: {
			requestId: id,
		},
		...optionsWithDefault,
	});
}

export function unlockCase(id: string): ApiActionRequest<unknown, string, string> {
	const unlockCaseEndpoint = ENDPOINT_UNLOCK_CASE
		.replace('{requestId}', id);
	return apiAction<unknown, string, string>({
		url: unlockCaseEndpoint,
		method: 'POST',
		label: UNLOCK_CASE,
		includeAccessToken: true,
		asDataOrParams: 'params',
		data: {
			requestId: id,
		},
		onSuccess: clearCaseLockStatus,
	});
}

export function caseLockStatus(id: string, lockTheCase: boolean, options: Partial<ApiActionPayload> = DEFAULT_OPTIONS): ApiActionRequest<unknown, ErrorStatusState, CaseLockDescriptor> {
	const lockStatusApiEndpoint = ENDPOINT_LOCK_CASE_STATUS
		.replace('{requestId}', id);

	const optionsWithDefault = {
		...DEFAULT_OPTIONS,
		...options,
	};

	function lockAndUpdateStatus(response: ApiResponseType<CaseLockDescriptor>) {
		return (dispatch: Dispatch) => {
			dispatch(updateCaseLockStatus(response));
			if (lockTheCase) {
				// undefined, 0 or other falsy value that does not match false should not be considered matching
				if (!response.data.locked) {
					dispatch(lockCase(id, optionsWithDefault));
					return optionsWithDefault.onSuccess(response);
				} else {
					const error = {
						isAxiosError: false,
						message: `Already locked by ${response.data.lastName}, ${response.data.firstName}.`,
						name: 'LOCKED_BY_ANOTHER_USER',
						response: undefined,
					} as AxiosError; // Cast to AxiosError since certain unnecessary properties left out intentionally
					optionsWithDefault.onFailure && optionsWithDefault.onFailure(error);
				}
			}
		};
	}

	return apiAction<unknown, ErrorStatusState, CaseLockDescriptor>({
		url: lockStatusApiEndpoint,
		method: 'GET',
		label: GET_CASE_LOCK_STATUS,
		includeAccessToken: true,
		asDataOrParams: 'params',
		...optionsWithDefault,
		onSuccess: lockAndUpdateStatus,
	});
}

export function bookmarkCase(id: string, successCallback: NoParamNoReturnCallback):  ApiActionRequest<unknown, ErrorStatusState, string>  {
	const bookMarkEndPoint = BOOKMARK_CASE
		.replace('{requestId}', id);

	const handleSuccess = () => {
		successCallback();
		return { type: EMPTY_ACTION };
	};

	return apiAction<unknown, ErrorStatusState, string>({
		url: bookMarkEndPoint,
		method: 'PUT',
		label: BOOKMARK_CASE,
		includeAccessToken: true,
		asDataOrParams: 'params',
		data: {
			requestId: id,
		},
		onSuccess: handleSuccess
	});
}

const deleteUrlParser = getCaseIdParserForTemplateUrl(ENDPOINT_DELETE_CASE_IN_USE_STATUS);
function deleteAccessorSuccess({ config: { url } }: AxiosResponse): CaseDetailsAction {
	const caseId = deleteUrlParser(url);
	return {
		type: DELETE_IN_USE_STATUS_SUCCESS,
		payload: caseId,
	};
}

export function deleteCaseInUseStatus(requestId: string, userId: string) {
	const deleteInUseStatusEndpoint = endpointTemplateToUrl(ENDPOINT_DELETE_CASE_IN_USE_STATUS, { requestId, userId });

	return apiAction({
		url: deleteInUseStatusEndpoint,
		method: 'DELETE',
		label: DELETE_IN_USE_STATUS,
		includeAccessToken: true,
		asDataOrParams: 'params',
		onSuccess: deleteAccessorSuccess,
	});
}

export function clearCaseDetails(): CaseDetailsAction {
	return {
		type: CLEAR_CASE_DETAILS,
	};
}
