export const RESET_FORM = 'RESET_FORM';

export const SAVE_FORM = 'SAVE_FORM';

export const REQUEST_FORM_DUMMY_LOADER = 'REQUEST_FORM_DUMMY_LOADER';

export const REQUEST_FORM_POST = 'REQUEST_FORM_POST';
export const SAVE_FORM_SUCCESS = 'SAVE_FORM_SUCCESS';
export const SAVE_FORM_ERROR = 'SAVE_FORM_ERROR';
export const CLEAR_FORM_ERROR = 'CLEAR_FORM_ERROR';
export const REQUEST_FORM_UPDATE = 'REQUEST_FORM_UPDATE';
export const ADD_START_TIME = 'ADD_START_TIME';

export const CLEAR_CASE_QUEUE = 'CLEAR_CASE_QUEUE';
export const NAVIGATE_CASE_QUEUE = 'NAVIGATE_CASE_QUEUE';
export const SET_CASE_QUEUE = 'SET_CASE_QUEUE';
export const GET_CASE_QUEUE_LIST = 'GET_CASE_QUEUE_LIST';
export const NAVIGATE_NEXT_CASE_QUEUE = 'NAVIGATE_NEXT_CASE_QUEUE';
export const QUEUE_STATUS_CLEAR = 'QUEUE_STATUS_CLEAR';

export const API_CANCELER_ADD = 'API_CANCELER_ADD';

export const API = 'API';
export const API_START = 'API_START';
export const API_END = 'API_END';
export const API_ERROR = 'API_ERROR';
export const ACCESS_DENIED = 'ACCESS_DENIED';

export const UPDATE_CASE_LIST = 'UPDATE_CASE_LIST';
export const UPDATE_CASE_LIST_ERROR = 'UPDATE_CASE_LIST_ERROR';
export const GET_CASE_LIST = 'GET_CASE_LIST';
export const GET_TASK_COUNT = 'GET_TASK_COUNT';
export const UPDATE_TASK_COUNT = 'UPDATE_TASK_COUNT';
export const UPDATE_CASE_LIST_FILTER = 'UPDATE_CASE_LIST_FILTER';
export const SAVE_SCROLL_POS = 'SAVE_SCROLL_POS';

export const UPDATE_CASE_DETAILS = 'UPDATE_CASE_DETAILS';
export const UPDATE_CASE_DETAILS_ERROR = 'UPDATE_CASE_DETAILS_ERROR';
export const UPDATE_CASE_ACCESSORS = 'UPDATE_CASE_ACCESSORS';
export const POST_CASE_ACCESSORS_REQUEST = 'POST_CASE_ACCESSORS_REQUEST';
export const GET_CASE_DETAILS = 'GET_CASE_DETAILS';
export const CANCEL_CASE = 'CANCEL_CASE';
export const LOCK_CASE = 'LOCK_CASE';
export const UNLOCK_CASE = 'UNLOCK_CASE';
export const UNLOCK_CASE_STATUS = 'UNLOCK_CASE_STATUS';
export const UPDATE_CASE_LOCK_STATUS = 'UPDATE_CASE_LOCK_STATUS';
export const CLEAR_CASE_LOCK_STATUS = 'CLEAR_CASE_LOCK_STATUS';
export const GET_CASE_LOCK_STATUS = 'GET_CASE_LOCK_STATUS';
export const DELETE_IN_USE_STATUS = 'DELETE_IN_USE_STATUS';
export const DELETE_IN_USE_STATUS_SUCCESS = 'DELETE_IN_USE_STATUS_SUCCESS';
export const CLEAR_CASE_DETAILS = 'CLEAR_CASE_DETAILS';

export const REQUIRE_AUTHENTICATION = 'REQUIRE_AUTHENTICATION';
export const AUTHENTICATION_LOADING = 'AUTHENTICATION_LOADING';
export const LOGOUT = 'LOGOUT';
export const SET_TOKEN = 'SET_TOKEN';
export const AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR';

export const REPLACE_ENDPOINT_CACHE = 'REPLACE_ENDPOINT_CACHE';
export const ENDPOINT_ERROR_CACHE = 'ENDPOINT_ERROR_CACHE';
export const SINGLE_APPEND_OR_REPLACE_CACHE = 'SINGLE_APPEND_OR_REPLACE_CACHE';
export const CLEAR_ALL_CACHE = 'CLEAR_ALL_CACHE';

export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_STATUS = 'UPDATE_USER_PROFILE_STATUS';
export const UPDATE_USER_SAVED_FILTER = 'UPDATE_USER_SAVED_FILTER';
export const SAVED_USER_PROFILE = 'SAVED_USER_PROFILE';
export const UPDATE_USER_PROFILE_NOTIFICATION = 'UPDATE_USER_PROFILE_NOTIFICATION';
export const UPDATE_USER_PROFILE_TERMS_OF_USE = 'UPDATE_USER_PROFILE_TERMS_OF_USE';
export const SAVED_USER_PROFILE_NOTIFICATION = 'SAVED_USER_NOTIFICATION';
export const SAVED_USER_PROFILE_TERMS_OF_USE = 'SAVED_USER_PROFILE_TERMS_OF_USE';
export const GET_TERMS_CONDITION = 'GET_TERMS_CONDITION';

export const START_SPINNER = 'START_SPINNER';
export const END_SPINNER = 'END_SPINNER';

export const EMPTY_ACTION = 'EMPTY_ACTION';

export const GET_SURGEON_LIST = 'GET_SURGEON_LIST';
export const UPDATE_SURGEON_LIST = 'UPDATE_SURGEON_LIST';
export const UPDATE_SURGEON_LIST_ERROR = 'UPDATE_SURGEON_LIST_ERROR';
export const UPDATE_ALL_SURGEON_LIST = 'UPDATE_ALL_SURGEON_LIST';
export const TOGGLE_PREFERRED_SURGEON = 'TOGGLE_PREFERRED_SURGEON';

export const SAVED_CONFIRM_ID = 'SAVED_CONFIRM_ID';
export const SAVED_CONFIRM_NUMBER_ERROR = 'SAVED_CONFIRM_NUMBER_ERROR';

export const GET_UNIT_LIST = 'GET_UNIT_LIST';
export const GET_UNITS_FOR_USER = 'GET_UNITS_FOR_USER';
export const UPDATE_UNIT_LIST = 'UPDATE_UNIT_LIST';
export const UPDATE_UNIT_LIST_ERROR = 'UPDATE_UNIT_LIST_ERROR';
export const UPDATE_ALL_UNIT = 'UPDATE_ALL_UNIT';
export const TOGGLE_PREFERRED_UNIT = 'TOGGLE_PREFERRED_UNIT';

export const GET_OTHER_FILTERS = 'GET_OTHER_FILTERS';
export const UPDATE_OTHER_FILTERS = 'UPDATE_OTHER_FILTERS';
export const ERROR_OTHER_FILTERS = 'ERROR_OTHER_FILTERS';
export const CREATE_NEW_TEMPLATE = 'CREATE_NEW_TEMPLATE';
export const CREATE_DUPLICATE_TEMPLATE = 'CREATE_DUPLICATE_TEMPLATE';
export const UPDATE_TEMPLATE = 'UPDATE_TEMPLATE';
export const LOCK_TEMPLATE = 'LOCK_TEMPLATE';
export const UNLOCK_TEMPLATE = 'UNLOCK_TEMPLATE';
export const DELETE_TEMPLATE = 'DELETE_TEMPLATE';
export const GET_TEMPLATE_LIST = 'GET_TEMPLATE_LIST';
export const GET_TEMPLATE_UPDATE_LIST = 'GET_TEMPLATE_UPDATE_LIST';
export const REPLACE_TEMPLATE_IN_LIST = 'REPLACE_TEMPLATE_IN_LIST';
export const POPULATE_TEMPLATE_LIST = 'POPULATE_TEMPLATE_LIST';
export const POPULATE_TEMPLATE_LIST_ERROR = 'POPULATE_TEMPLATE_LIST_ERROR';
export const UPDATE_TEMPLATE_STATUS = 'UPDATE_TEMPLATE_STATUS';

export const CLEAR_TOAST_STATUS = 'CLEAR_TOAST_STATUS';
export const SET_TOAST_STATUS = 'SET_TOAST_STATUS';

export const CLEAR_APP_ALERT = 'CLEAR_APP_ALERT';
export const SET_APP_ALERT = 'SET_APP_ALERT';

export const SET_ERROR_STATUS = 'SET_ERROR_STATUS';
export const CLEAR_ERROR_STATUS = 'CLEAR_ERROR_STATUS';

export const GET_ROOM_EVENTS = 'GET_ROOM_EVENTS';

export const CREATE_UNAVAILABLE_EVENT = 'CREATE_UNAVAILABLE_EVENT';
export const EDIT_UNAVAILABLE_EVENT = 'EDIT_UNAVAILABLE_EVENT';
export const DELETE_UNAVAILABLE_EVENT = 'DELETE_UNAVAILABLE_EVENT';

export const GET_DOC_PERMISSION = 'GET_DOC_PERMISSION';
export const DOC_PRINT = 'DOC_PRINT';
export const UPDATE_USER_DOC_VIEW_PERMISSION = 'UPDATE_USER_DOC_VIEW_PERMISSION';
export const GET_DOC_INDEXES = 'GET_DOC_INDEXES';
export const SAVE_DOC_INDEXES = 'SAVE_DOC_INDEXES';
export const GET_DOC = 'GET_DOC';

export const CREATE_HOLD_EVENT = 'CREATE_HOLD_EVENT';
export const UPDATE_HOLD_EVENT = 'UPDATE_HOLD_EVENT';
export const DELETE_HOLD_EVENT = 'DELETE_HOLD_EVENT';

export const GET_BLOCKTIME = 'GET_BLOCKTIME';
export const POST_FIND_A_TIME_SEARCH = 'POST_FIND_A_TIME_SEARCH';
export const GET_SLOTS = 'GET_SLOTS';

export const GET_CASE_ACTIVITIES = 'GET_CASE_ACTIVITIES';

export const UPDATE_SELECTED_FACILITY = 'UPDATE_SELECTED_FACILITY';

export const UPDATE_SELECTED_SURGEONS = 'UPDATE_SELECTED_SURGEONS';

export const GET_PROCEDURES = 'GET_PROCEDURES';

export const GET_PROCEDURE_DURATION = 'GET_PROCEDURE_DURATION';

export const GET_EQUIPMENT_LIST = 'GET_EQUIPMENT_LIST';

export const UPDATE_AUTH_STATUS_TYPES = 'UPDATE_AUTH_STATUS_TYPES';
export const UPDATE_CASE_AUTH_STATUS = 'UPDATE_CASE_AUTH_STATUS';
