import React from 'react';
import { DisplayText } from '@components/DisplayText/DisplayText';
import useDetailSectionGrid, {
	AuthorizationGridFields
} from '@components/CaseDetails/useDetailSectionGrid/useDetailSectionGrid';
import { useSelector } from 'react-redux';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import DetailSection from '@components/CaseDetails/DetailSection';
import {
	useInsuranceAuthStatusConfig
} from '@utilities/hooks/useInsuranceAuthStatusConfig/useInsuranceAuthStatusConfig';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import { useCollectionEntity } from '@utilities/hooks/useCollectionEntity/useCollectionEntity';
import { ENDPOINT_USER_UNITS } from '@utilities/apiConstants';
import { Unit } from '@data/unit/Unit';
import { utcToTimezone } from '@utilities/dateUtilities';
import {
	AuthorizationComments
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationComments/AuthorizationComments';
import BoxFancyScroll from '@components/BoxFancyScroll/BoxFancyScroll';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
	useCaseDetailsPageContext
} from '@components/CaseDetails/CaseDetailsContext/CaseDetailsPageContext/CaseDetailsPageContext';
import type { Theme as DefaultTheme } from '@material-ui/core/styles/createTheme';
import {
	AuthorizationActionTaken
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/AuthorizationActionTaken';

const useStyles = makeStyles<DefaultTheme, Record<'showCondensedView', boolean>>(() => ({
	root: ({ showCondensedView }) => ({
		'maxHeight': showCondensedView ? 'unset' : '500px',
		'overflowY': 'auto',
		'& .MuiTypography-root': {
			fontFamily: 'Lato',
		}
	}),
}));

export const AuthorizationInformationDisplay: React.FC = () => {
	const { showCondensedView } = useCaseDetailsPageContext();
	const classes = useStyles({ showCondensedView });
	const { gridContainer } = useDetailSectionGrid(DetailSection.AUTHORIZATION);

	const { insuranceAuthorization, unit } = useSelector(CaseDetailsInfoSelector);
	const {
		authNumber,
		status,
		startDate,
		expireDate,
		lastUpdated
	} = insuranceAuthorization || {};

	const { label: insuranceAuthStatusLabel } = useInsuranceAuthStatusConfig(status) || {};
	const unitInfo = useCollectionEntity<Unit>(ENDPOINT_USER_UNITS, unit);

	const convertedLastUpdated = lastUpdated &&
		unitInfo?.hospitalTimeZone &&
		utcToTimezone(lastUpdated, unitInfo.hospitalTimeZone, 'MM/dd/yyyy h:mm a');

	const displayLastUpdated = React.useMemo(() => {
		if (!convertedLastUpdated) return null;
		const [date, time, period] = convertedLastUpdated.split(' ');
		return `${date} • ${time} ${period}`;
	}, [convertedLastUpdated]);

	const parsedStartDate = startDate && parseISO(startDate);
	const parsedExpireDate = expireDate && parseISO(expireDate);

	const formattedStartDate = parsedStartDate &&
		unitInfo?.hospitalTimeZone &&
		formatInTimeZone(parsedStartDate, unitInfo.hospitalTimeZone, 'MM/dd/yyyy');

	const formattedExpireDate = parsedExpireDate &&
		unitInfo?.hospitalTimeZone &&
		formatInTimeZone(parsedExpireDate, unitInfo.hospitalTimeZone, 'MM/dd/yyyy');

	return (
		<BoxFancyScroll
			className={classNames(gridContainer, classes.root)}
			data-field="caseDetails-authSection-container"
		>
			<DisplayText
				label="Authorization status"
				defaultValue="--"
				displayDefault={!insuranceAuthStatusLabel}
				data-field="caseDetails-authSection-authStatus"
				style={{ gridArea: AuthorizationGridFields.AUTH_STATUS }}
			>
				{insuranceAuthStatusLabel}
			</DisplayText>
			<DisplayText
				label="Authorization number"
				defaultValue="--"
				displayDefault={!authNumber}
				data-field="caseDetails-authSection-authNumber"
				style={{ gridArea: AuthorizationGridFields.AUTH_NUMBER }}
			>
				{authNumber}
			</DisplayText>
			<DisplayText
				label="Authorization start"
				defaultValue="--"
				displayDefault={!startDate}
				data-field="caseDetails-authSection-authStart"
				style={{ gridArea: AuthorizationGridFields.AUTH_START }}
			>
				{formattedStartDate}
			</DisplayText>
			<DisplayText
				label="Authorization expire"
				defaultValue="--"
				displayDefault={!expireDate}
				data-field="caseDetails-authSection-authExpire"
				style={{ gridArea: AuthorizationGridFields.AUTH_EXPIRE }}
			>
				{formattedExpireDate}
			</DisplayText>
			<DisplayText
				label="Status last updated"
				defaultValue="--"
				displayDefault={!displayLastUpdated}
				data-field="caseDetails-authSection-authStatusLastUpdated"
				style={{ gridArea: AuthorizationGridFields.AUTH_LAST_UPDATED }}
			>
				{displayLastUpdated}
			</DisplayText>
			<AuthorizationActionTaken />
			<AuthorizationComments />
		</BoxFancyScroll>
	);
};
