import { combineReducers, Reducer } from 'redux';
import { RequestFormReducer } from './RequestFormReducer';
import { CaseListReducer } from './CaseListReducer';
import { RootState } from '@interfaces/rootState';
import { CaseDetailsReducer } from './CaseDetailsReducer';
import { AuthReducer } from './AuthReducer';
import { UserProfileReducer } from './UserProfileReducer';
import { ApiCacheReducer } from './ApiCacheReducer';
import { SurgeonListReducer } from './SurgeonListReducer';
import { CaseQueueReducer } from './CaseQueueReducer';
import { UnitListReducer } from './UnitListReducer';
import { UtilityReducer } from './UtilityReducer';
import { TemplateListReducer } from './TemplateListReducer';
import TemplateStatusReducer from './TemplateStatusReducer';
import ToastStatusReducer from './ToastStatusReducer';
import AppAlertReducer from './AppAlertReducer';
import { ErrorStatusReducer } from './ErrorStatusReducer';
import { ApiStatusReducer } from './ApiStatusReducer';
import { SharedFiltersReducer } from '@store/reducers/SharedFiltersReducer';
import { InsuranceAuthStatusReducer } from '@store/reducers/InsuranceAuthStatusReducer';

export const rootReducer: Reducer<RootState> = combineReducers({
	requestForm: RequestFormReducer,
	caseList: CaseListReducer,
	caseDetails: CaseDetailsReducer,
	surgeonList: SurgeonListReducer,
	auth: AuthReducer,
	apiCache: ApiCacheReducer,
	appAlert: AppAlertReducer,
	userProfile: UserProfileReducer,
	caseQueue: CaseQueueReducer,
	unitList: UnitListReducer,
	utilityState: UtilityReducer,
	templateList: TemplateListReducer,
	templateStatus: TemplateStatusReducer,
	toastStatus: ToastStatusReducer,
	errorStatus: ErrorStatusReducer,
	apiStatus: ApiStatusReducer,
	sharedFilters: SharedFiltersReducer,
	insuranceAuthStatus: InsuranceAuthStatusReducer,
});
