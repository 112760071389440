import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { Tooltip } from '@components/Tooltip/Tooltip';
import { makeStyles } from '@material-ui/core/styles';
import DisplaySelectedUnitTimeFrame from '@components/SchedulePage/DisplaySelectedUnitTimeFrame/DisplaySelectedUnitTimeFrame';
import { useBlockEventConfig } from '@components/SchedulePage/utilities/useBlockEventConfig/useBlockEventConfig';
import CopyText from '@components/CopyText/CopyText';
import classNames from 'classnames';
import BuildIcon from '@material-ui/icons/Build';
import { ManageScheduleEventMenu } from '@components/SchedulePage/ManageScheduleEventMenu/ManageScheduleEventMenu';
import BlockEventProps from '@components/SchedulePage/BlockEvent/BlockEventProps';
import { ModalContextProvider } from '@components/Modal/ModalContextProvider';
import { SchedulingCancelCaseModal } from '@components/SchedulePage/ManageScheduleEventMenu/SchedulingCancelCaseModal/SchedulingCancelCaseModal';
import { CaseLockModal } from '@components/CaseDetails/ManageCaseDropDown/DropDownOptions/EditCase/CaseLockModal/CaseLockModal';
import { SchedulingCopyCaseModal } from '@components/SchedulePage/ManageScheduleEventMenu/SchedulingCopyCaseModal/SchedulingCopyCaseModal';
import { CopyCaseProvider } from '@components/CaseDetails/CopyCaseContext/CopyCaseContext';
import { GapDisplay } from '@components/SchedulePage/BlockEvent/GapDisplay/GapDisplay';
import { useInsuranceAuthStatusConfig } from '@utilities/hooks/useInsuranceAuthStatusConfig/useInsuranceAuthStatusConfig';

const useStyles = makeStyles((theme) => ({
	root: {
		margin: theme.spacing(0, 2, 2, 2),
		padding: theme.spacing(1),
		borderRadius: theme.spacing(0.5),
		borderWidth: theme.spacing(0.25),
		position: 'relative',
	},
	blockProcedureLabel: {
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.primary.dark,
	},
	nonBlockProcedureLabel: {
		fontWeight: theme.typography.fontWeightBold,
		color: theme.palette.secondary.dark,
	},
	eventIcon: {
		borderRadius: theme.spacing(0.5),
		fill: theme.palette.background.paper,
		position: 'absolute',
		top: -0.4,
		right: -0.4,
		backgroundColor: theme.palette.secondary.main,
		borderColor: theme.palette.secondary.main,
		borderStyle: 'solid',
	},
	FIN: {
		display: 'flex',
		gap: theme.spacing(1),
	},
	FINVal: {
		flex: 'auto 1 1',
	},
	FINCopyIcon: {
		height: '18px',
		width: '18px',
	},
	procedureName: {
		height: '1.25rem',
		overflow: 'clip',
		textDecoration: 'underline',
		display: 'inline-block',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		maxWidth: '100%',
	},
	manageCaseMenu: {
		display: 'flex',
		justifyContent: 'center',
	},
}));

const BlockEvent: React.FC<BlockEventProps> = ({ eventInfo, displayMenu = true }) => {
	const mainClass = useStyles();
	const { cardStyles } = useBlockEventConfig(eventInfo);
	const authorizationLabel = useInsuranceAuthStatusConfig(eventInfo?.authorizationStatus);

	const blockProcedureLabelText: string = eventInfo.isBlockEvent
		? 'Block Procedure'
		: 'Non-Block Procedure';

	return (
		<>
			<Box className={mainClass.root} style={cardStyles} data-field="block-event-container">
				{eventInfo.isNeedsRevision && (
					<BuildIcon data-field="block-event-icon" className={mainClass.eventIcon} />
				)}
				<Typography
					variant="body2"
					className={classNames({
						[mainClass.blockProcedureLabel]: eventInfo.isBlockEvent,
						[mainClass.nonBlockProcedureLabel]: !eventInfo.isBlockEvent,
					})}
				>
					{blockProcedureLabelText}
				</Typography>
				{eventInfo.isPracticeEvent && (
					<>
						<Typography variant="body2">Pt: {eventInfo.patient}</Typography>
						<Tooltip
							data-field="scheduling-eventCard-procedureToolTip"
							title={eventInfo.procedure || ''}
							placement="top-start"
							arrow
						>
							<Typography className={mainClass.procedureName} variant="body2">
								{eventInfo.procedure}
							</Typography>
						</Tooltip>
					</>
				)}

				<DisplaySelectedUnitTimeFrame
					timeFrame={{
						start: eventInfo.startDate,
						end: eventInfo.end ? eventInfo.endDate : undefined,
					}}
					variant="body2"
				/>
				<Typography variant="body2">{eventInfo.room}</Typography>
				{authorizationLabel && (
					<Typography variant="body2">Auth: {authorizationLabel.label}</Typography>
				)}
				{eventInfo.fin && (
					<Box className={mainClass.FIN}>
						<Typography variant="body2">FIN: </Typography>
						<CopyText
							successMessage="FIN has been copied"
							className={mainClass.FINVal}
							typographyProps={{ variant: 'body2' }}
							iconButtonProps={{ className: mainClass.FINCopyIcon }}
						>
							{eventInfo.fin}
						</CopyText>
					</Box>
				)}

				{ displayMenu &&
					<Box className={mainClass.manageCaseMenu}>
						<ModalContextProvider>
							<ManageScheduleEventMenu eventInfo={eventInfo} />
							<SchedulingCancelCaseModal eventInfo={eventInfo} />
							<CaseLockModal />
							<CopyCaseProvider>
								<SchedulingCopyCaseModal />
							</CopyCaseProvider>
						</ModalContextProvider>
					</Box>
				}
			</Box>
			{eventInfo.gapAfterDisplay && <GapDisplay gapDetails={eventInfo.gapAfterDisplay} />}
		</>
	);
};

export default BlockEvent;
