import React from 'react';
import { useSchedulePageContext } from '@components/SchedulePage/SchedulePageContext/SchedulePageContext';
import BlockColumn from '@components/SchedulePage/BlockColumn/BlockColumn';
import UnableToLoadScheduleError from '@components/SchedulePage/ScheduleError/UnableToLoadScheduleError';
import BoxScrollHorizontal from '@components/BoxScrollHorizontal/BoxScrollHorizontal';
import EmptyScheduleMessage from '@components/SchedulePage/EmptyScheduleMessage/EmptyScheduleMessage';

const ScheduleColumnsContainer = () => {
	const { apiError, schedules, getSchedules } = useSchedulePageContext();

	React.useEffect(() => {
		getSchedules();
	}, [getSchedules]);

	return (
		<BoxScrollHorizontal data-field="schedule-columns-container" columnScrollAmount={274}>
			{schedules && !apiError ? (
				schedules.length > 0 ? (
					schedules.map((b) => <BlockColumn key={b.id} blockInfo={b} />)
				) : (
					<EmptyScheduleMessage />
				)
			) : (
				<UnableToLoadScheduleError />
			)}
		</BoxScrollHorizontal>
	);
};

export default ScheduleColumnsContainer;
