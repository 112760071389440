import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Theme } from '@material-ui/core';
import { format } from 'date-fns';
import CaseActivityUserAndRole from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityUserAndRole/CaseActivityUserAndRole';
import CaseActivityDescription from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityDescription/CaseActivityDescription';
import CaseActivityReason from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityReason/CaseActivityReason';
import CaseActivityMessage from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityMessage/CaseActivityMessage';
import CaseActivityContainer from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityContainer/CaseActivityContainer';
import CaseActivityAvatar from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityAvatar/CaseActivityAvatar';
import CaseActivityDateAndTime from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityDateAndTime/CaseActivityDateAndTime';
import { CaseActivityChanges } from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityChanges/CaseActivityChanges';
import CaseActivityRowType from '@components/CaseActivityDrawer/types/CaseActivityRowType';
import { getDateFromApiDateTime } from '@calendar';
import Chip from '@components/Chip/Chip';
import { arraysOverlap } from '@utilities/commonFunction';
import { HospitalRoles, OfficeRoles } from '@interfaces/UserProfile/UserRoleEnum';
import { AppointmentType } from '@data/request/AppointmentType';
import getActivityTypeConfig from '@components/CaseActivityDrawer/CaseActivityRow/getActivityTypeConfig';
import { CaseActivityTypeEnum } from '@interfaces/CaseActivityTypeEnum';
import { CaseActivityGridFields } from '@components/CaseActivityDrawer/presentationalComponents/CaseActivityContainer/CaseActivityContainer.types';

const useStyles = makeStyles((theme: Theme) => ({
	chip: {
		marginBottom: theme.spacing(0.25),
	},
	avatar: {
		display: 'flex',
		flexDirection: 'column',
		gridArea: `${CaseActivityGridFields.AVATAR}`,
		[theme.breakpoints.down('md')]: {
			display: 'none',
		},
	},
	title: {
		display: 'flex',
		flexDirection: 'column',
		gridArea: `${CaseActivityGridFields.TITLE}`,
	},
	status: {
		alignItems: 'end',
		display: 'flex',
		flexDirection: 'column',
		gridArea: `${CaseActivityGridFields.STATUS}`,
		textAlign: 'right',
	},
	changes: {
		gridArea: `${CaseActivityGridFields.CHANGES}`,
	},
	reason: {
		gridArea: `${CaseActivityGridFields.REASON}`,
	},
}));

interface CaseActivityRowProps {
	caseActivity: CaseActivityRowType;
	appointmentType?: AppointmentType;
}

const attachmentTypes = [
	CaseActivityTypeEnum.ATTACHMENT,
	CaseActivityTypeEnum.DOCUMENT_SCAN_FAILED,
];

const CaseActivityRow: React.FC<CaseActivityRowProps> = ({ caseActivity, appointmentType }) => {
	const classes = useStyles();
	const { message, firstName, lastName, reason, userRoles, createdTime, changes } = caseActivity;

	const hasHospitalRole = arraysOverlap(userRoles, HospitalRoles);
	const hasOfficeRole = arraysOverlap(userRoles, OfficeRoles);

	const initials =
		firstName && lastName && userRoles.length
			? `${firstName[0]}${lastName[0]}`.toUpperCase()
			: 'EHR';

	const fullNameAndRole =
		firstName && lastName && userRoles.length
			? `${firstName} ${lastName}${
				hasHospitalRole ? ' - OR' : hasOfficeRole ? ' - Office' : ''
			}`
			: 'EHR User';

	const formattedTime = createdTime
		? format(getDateFromApiDateTime(createdTime), 'MM/dd/yyyy h:mm aa')
		: '';

	const chipVariant =
		getActivityTypeConfig(appointmentType)[caseActivity.activityType]?.chipVariant;
	const chipContent =
		getActivityTypeConfig(appointmentType)[caseActivity.activityType]?.chipContent;
	const description =
		getActivityTypeConfig(appointmentType)[caseActivity.activityType]?.description;

	return (
		<CaseActivityContainer hasChanges={!!changes}>
			<CaseActivityAvatar className={classes.avatar} data-field="caseActivity-avatar">
				{initials}
			</CaseActivityAvatar>
			<Box className={classes.title}>
				<CaseActivityUserAndRole data-field="caseActivity-userAndRole">
					{fullNameAndRole}
				</CaseActivityUserAndRole>
				<CaseActivityDescription data-field="caseActivity-helperText">
					{description}
				</CaseActivityDescription>
			</Box>
			<Box className={classes.status}>
				{chipVariant && chipContent && (
					<Chip
						className={classes.chip}
						data-field="caseActivity-chip"
						color={chipVariant}
					>
						{chipContent}
					</Chip>
				)}
				<CaseActivityDateAndTime data-field="caseActivity-dateAndTime">
					{formattedTime}
				</CaseActivityDateAndTime>
			</Box>

			<Box className={classes.changes}>
				{changes?.replacedNodes?.length && changes.replacedNodes.map(change => (
					<Box mb={3} key={change.fieldFriendlyName}>
						<CaseActivityChanges change={change} />
					</Box>
				))}
			</Box>

			<Box className={classes.reason}>
				{reason && (
					<CaseActivityReason data-field="caseActivity-reason">
						{reason}
					</CaseActivityReason>
				)}
				{message && (
					<CaseActivityMessage
						data-field="caseActivity-message"
						isAttachmentActivity={attachmentTypes.includes(caseActivity.activityType)}
					>
						{message}
					</CaseActivityMessage>
				)}
			</Box>
		</CaseActivityContainer>
	);
};

export default CaseActivityRow;
