import { ReplacedNode } from '@components/CaseActivityDrawer/types/CaseActivityRowType';
import {
	useInsuranceAuthStatusConfig
} from '@utilities/hooks/useInsuranceAuthStatusConfig/useInsuranceAuthStatusConfig';

interface ChangeDetails extends Partial<ReplacedNode> {
	changeTitle: string;
}

export const useFormatActivityChangeDetails = (change: ReplacedNode) => {
	const [changedField, procedureName] = change.fieldFriendlyName.split('::');

	const changedFieldDetails: Record<string, ChangeDetails> = {
		Authorization: {
			currValue: useInsuranceAuthStatusConfig(change.currValue)?.label,
			prevValue: useInsuranceAuthStatusConfig(change.prevValue)?.label,
			changeTitle: 'Authorization status update.',
		},
		Equipment: {
			changeTitle: `Procedure ${procedureName} was edited.`,
			currValue: change.currValue || 'No equipment',
			prevValue: change.prevValue || 'No equipment',
		},
	};

	const changeDetails = changedFieldDetails[changedField] || {
		...change,
		changeTitle: 'This request was edited.',
	};

	return { changedField, changeDetails };
};
