import React from 'react';
import { useSelector } from 'react-redux';
import { InsuranceAuthStatusTypesSelector } from '@store/selectors/InsuranceAuthStatusTypesSelector';

/**
 * Custom hook to get authorization actions by a specific flag.
 *
 * @param flag - The flag to filter authorization actions by.
 * @returns An array of objects containing the label and value of the matching authorization actions.
 * @example
 * const actions = useGetAuthActionsByFlag('flag1');
 * console.log(actions); // [{ label: 'Authorization 1', value: 'AUTH1' }]
 */

export const useGetAuthActionsByFlag = (flag: string) => {
	const authStatusConfigTypes = useSelector(InsuranceAuthStatusTypesSelector);

	return React.useMemo(() => {
		return authStatusConfigTypes
			? Object.entries(authStatusConfigTypes)
				.filter(([, { flags }]) => flags?.includes(flag))
				.map(([key, { label }]) => ({
					label,
					value: key,
				}))
			: [];
	}, [authStatusConfigTypes, flag]);
};
