import { CaseActivityTypeEnum } from '@interfaces/CaseActivityTypeEnum';
import { MUI_STYLE_VARIANTS } from '@theme/themeConstants';
import { AppointmentType } from '@data/request/AppointmentType';

interface ActivityTypeDescriptor {
	chipContent?: string;
	chipVariant?: MUI_STYLE_VARIANTS;
	description: string;
}

const getActivityTypeConfig: (appointmentType?: AppointmentType) => Partial<Record<CaseActivityTypeEnum, ActivityTypeDescriptor>> = (appointmentType) => ({
	[CaseActivityTypeEnum.APPROVED_REVISION]: {
		chipContent: 'Resolved',
		chipVariant: 'primary',
		description: 'Approved the revision',
	},
	[CaseActivityTypeEnum.ATTACHMENT]: {
		description: 'Added attachment',
	},
	[CaseActivityTypeEnum.CANCELLED]: {
		chipContent: 'Canceled',
		chipVariant: 'default',
		description: 'Request to cancel the procedure was confirmed',
	},
	[CaseActivityTypeEnum.CONFIRMED]: {
		chipContent: 'Scheduled',
		chipVariant: 'success',
		description: 'Confirmed case in system',
	},
	[CaseActivityTypeEnum.DOCUMENT_SCAN_FAILED]: {
		description: 'Virus Scan Failed',
	},
	[CaseActivityTypeEnum.NEEDS_REVISION]: {
		chipContent: 'Needs Revision',
		chipVariant: 'secondary',
		description: 'Requested a revision for the case',
	},
	[CaseActivityTypeEnum.PENDING_CANCELLED]: {
		chipContent: 'Pending Cancellation',
		chipVariant: 'error',
		description: 'Case was requested to be cancelled',
	},
	[CaseActivityTypeEnum.REJECTED_REVISION]: {
		chipContent: 'Declined',
		chipVariant: 'error',
		description: 'Revision has been declined',
	},
	[CaseActivityTypeEnum.REVISED]: {
		chipContent: 'Revised',
		chipVariant: 'secondary',
		description: 'This case has been revised',
	},
	[CaseActivityTypeEnum.SUBMIT_REVISION]: {
		description: 'Edited the case',
	},
	[CaseActivityTypeEnum.SUBMITTED]: {
		chipContent: 'Unscheduled',
		chipVariant: 'warning',
		description: appointmentType && appointmentType === AppointmentType.DOCUMENT
			? 'Case was submitted as a Document Request'
			: 'Case was submitted as a Web Request',
	},
	[CaseActivityTypeEnum.UPDATE_AUTHORIZATION]: {
		chipContent: 'Update',
		chipVariant: 'primary',
		description: 'Authorization status change'
	},
});

export default getActivityTypeConfig;
