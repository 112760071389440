import { Reducer } from 'redux';
import {
	CLEAR_CASE_DETAILS,
	CLEAR_CASE_LOCK_STATUS,
	DELETE_IN_USE_STATUS_SUCCESS,
	UPDATE_CASE_ACCESSORS, UPDATE_CASE_AUTH_STATUS,
	UPDATE_CASE_DETAILS,
	UPDATE_CASE_DETAILS_ERROR,
	UPDATE_CASE_LOCK_STATUS
} from '../actions/types';
import { CaseDetailsState } from '@interfaces/CaseDetails/CaseDetailsState';
import { CaseDetailsAction } from '@interfaces/CaseDetails/CaseDetailsAction';
import { utcToTimezone } from '@utilities/dateUtilities';
import { isWebRequest } from '@utilities/typeAssertionUtilities';

const initialState: CaseDetailsState = {};

export const CaseDetailsReducer: Reducer<CaseDetailsState, CaseDetailsAction>
	= (state = initialState, action: CaseDetailsAction) => {
		switch (action.type) {
		case UPDATE_CASE_DETAILS:
			return {
				details: {
					...action.payload,
					appointmentDetail: {
						...action.payload.appointmentDetail,
						procedureDate: utcToTimezone(action.payload.appointmentDetail.procedureDate, action.payload.hospitalTimeZone),
					},
					[isWebRequest(action.payload) ? 'patDetail' : '']: isWebRequest(action.payload) ? {
						...action.payload.patDetail,
						date: action.payload.patDetail.date && utcToTimezone(action.payload.patDetail.date, action.payload.hospitalTimeZone)
					} : undefined
				},
				error: null
			};
		case	UPDATE_CASE_AUTH_STATUS:
			return state.details && state.details.insuranceAuthorization && action.payload ? {
				...state,
				details: {
					...state.details,
					insuranceAuthorization: {
						...state.details.insuranceAuthorization,
						status: action.payload.actionTaken,
					},
				},
			} : state;
		case UPDATE_CASE_ACCESSORS:
			return state.details && state.details.id === action.payload.caseId ? {
				...state,
				details: {
					...state.details,
					accessors: action.payload.accessors,
				},
			} : state;
		case UPDATE_CASE_DETAILS_ERROR:
			return {
				...state,
				error: action.error
			};
		case CLEAR_CASE_LOCK_STATUS:
			return {
				...state,
				lockStatus: {},
				error: null
			};
		case UPDATE_CASE_LOCK_STATUS:
			return {
				...state,
				lockStatus: { ...action.payload },
			};
		case DELETE_IN_USE_STATUS_SUCCESS:
			return state.details && state.details.id === action.payload ? {
				...state,
				details: {
					...state.details,
					accessors: undefined,
				},
			} : state;
		case CLEAR_CASE_DETAILS:
			return initialState;
		}
		return state;
	};
