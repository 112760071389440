import { apiAction } from '@store/middlewares/api/ApiActionCreators';
import { ENDPOINT_SURGEON_TIMES } from '@utilities/apiConstants';
import ApiService from '@utilities/apiEndpoints/ApiService';
import { GET_BLOCKTIME } from '@store/actions/types';
import { CancelPolicy } from '@interfaces/CancelPolicy';
import { ScheduleRequest } from '@data/schedules/types/ScheduleRequest';

export const getSchedules = (apiActionRequest?: Partial<ScheduleRequest>) => {
	return apiAction({
		url: ENDPOINT_SURGEON_TIMES,
		apiService: ApiService.BTS,
		includeAccessToken: true,
		label: GET_BLOCKTIME,
		cancelPolicy: CancelPolicy.NO_DUPLICATE_PATH,
		shouldDisableErrorHandler: true,
		shouldDisableLoadIndicator: true,
		...apiActionRequest,
	});
};
