import React from 'react';
import { StatefulSelect } from '@components/StatefulInputs/StatefulSelect/StatefulSelect';
import {
	useGetAuthActionsByFlag
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/hooks/useGetAuthActionsByFlag';
import { AuthorizationActionSchema } from '@utilities/Validation/validationSchema';
import { Typography } from '@material-ui/core';
import { AUTH_ACTION_TAKEN_HELPER_TEXT } from '@utilities/constants';
import { useFormContext } from 'react-hook-form';
import {
	AuthorizationActionSelectApi
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/AuthorizationActionSelectApi';
import { makeStyles } from '@material-ui/core/styles';

const OFFICE_ACTION_TAKEN = 'OFFICE_ACTION_TAKEN';

const useStyles = makeStyles((theme) => ({
	helperText: {
		fontSize: '0.875rem',
		fontWeight: 400,
		color: theme.palette.grey[700],
	},
}));

export const AuthorizationActionSelect: React.FC = () => {
	const classes = useStyles();

	const { formState } = useFormContext<AuthorizationActionSelectApi>();
	const { errors } = formState;
	const officeActionTakenOptions = useGetAuthActionsByFlag(OFFICE_ACTION_TAKEN);

	return (
		<>
			<StatefulSelect
				dataField="caseDetails-authorization-actionTaken"
				name="actionTaken"
				label="Action taken"
				placeholder="Choose Action"
				options={officeActionTakenOptions}
				controllerOptions={{
					rules: AuthorizationActionSchema.actionTaken,
				}}
			/>
			{!errors.actionTaken &&
				<Typography className={classes.helperText}>
					{ AUTH_ACTION_TAKEN_HELPER_TEXT }
				</Typography>
			}
		</>
	);
};
