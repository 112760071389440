import { Button } from '@ascension/ui-library';
import React from 'react';
import { updateCaseAuthStatusInStore, updateInsuranceAuthStatus } from '@store/actions/InsuranceAuthStatusActionCreators';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import {
	AuthorizationActionSelectApi
} from '@components/CaseDetails/AuthorizationInformationDisplay/AuthorizationActionTaken/AuthorizationActionSelectApi';
import { CaseDetailsInfoSelector } from '@store/selectors/CaseDetailsSelector';
import { makeStyles } from '@material-ui/core/styles';
import { Dispatch } from 'redux';
import { useToast } from '@utilities/hooks/useToast/useToast';
import { ToastType } from '@interfaces/ToastStatus/ToastStatusState';
import { ToastConstants } from '@utilities/toastConstants';

const useStyles = makeStyles((theme) => ({
	submitButton: {
		borderRadius: '100px',
		height: '48px',
		marginTop: theme.spacing(2),
	},
}));

export const AuthorizationActionSubmit = () => {
	const classes = useStyles();

	const dispatch = useDispatch();
	const { insuranceAuthorization } = useSelector(CaseDetailsInfoSelector);
	const { id } = insuranceAuthorization || {};
	const { getValues, handleSubmit } = useFormContext<AuthorizationActionSelectApi>();
	const authSuccessToast = useToast();

	const handleOnSubmit = () => {
		const authAction = { actionTaken: getValues('actionTaken') };
		const onSuccess = () => {
			return (dispatch: Dispatch) => {
				dispatch(updateCaseAuthStatusInStore(authAction));
				authSuccessToast(ToastType.SUCCESS, ToastConstants.AUTH_STATUS_UPDATED);
			};
		};

		id && dispatch(updateInsuranceAuthStatus(id, authAction, { onSuccess }));
	};

	return (
		<Button
			variant="contained"
			color="primary"
			className={classes.submitButton}
			onClick={handleSubmit(handleOnSubmit)}
		>
			Submit
		</Button>
	);
};
