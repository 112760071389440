import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ApiCacheSelector } from '@store/selectors/ApiCacheSelector';
import { getCollection } from '@store/actions/CollectionActionCreators';
import { ApiActionPayload } from '@interfaces/ApiAction/ApiActionPayload';

const DEFAULT_COLLECTION: Array<never> = [];

/***
 * useDeferredCollection
 *
 * @description
 * This is the plumbing for useCollection and useCollectionEntity. In most cases, you should use those hooks instead.
 * This hook can be used if you need to control when the list is retrieved from the cache or API like if you need to
 * first determine the practice or other resource dependent endpoint. The useCollection and useCollectionEntity hooks
 * will do that automatically.
 *
 *  The useDeferredCollection hook contains the logic to either retrieve the current collection from the cache or request the collection from the API.
 *  It returns a callback that can be used to initialize the collection when ready. This is a holdover from the original
 *  useCollectionEntity implementation. At that time, it was necessary to wait to request the collection until the entity ID
 *  could be determined.
 *
 *  Regarding the cancel functionality, the hook also allows cancelling the collection return to avoid loading the incorrect name to different instances.
 *  A cancel may happen in a case where a user is navigating through cases and clicks next case before the list of surgeons has been downloaded.
 *  The cancel in that case wouldn't affect the UI because the next case would show the surgeon and unit information immediately
 *  now that it would be downloaded in the cache.
 *
 * @param collectionEndpoint {string} - should be the path for an endpoint that returns an array from the SSM service
 * @param overrides {Partial<ApiActionPayload<EntityType[]>>} - optional overrides for the API call
 *
 * @returns [collection, initializeCollection] {[ EntityType[], () => AbortController ]}
 *  - collection, the first item, is the result. It will default to an empty array if a collection is not available.
 *  - initializeCollection - method to grab the collection from the cache or make the API call to get the collection if needed.
 *      - @returns AbortController that can be used to cancel the API call.
 *
 * @example
 * const useCollection = <EntityType extends {}>(collectionEndpoint: string): EntityType[] => {
 *      const [collection, updateCollection] = useDeferredCollection<EntityType>(collectionEndpoint);
 *
 * 	    React.useEffect(() => {
 * 		    const abortController = updateCollection();
 * 		    return () => abortController.abort();
 * 	    }, [updateCollection]);
 *
 * 	    return collection;
 * };
 */
const useDeferredCollection = <EntityType extends object>(collectionEndpoint: string, overrides?: Pick<ApiActionPayload<EntityType[]>, 'shouldDisableErrorHandler' | 'shouldDisableLoadIndicator'>): [EntityType[], () => AbortController] => {
	const dispatch = useDispatch();
	const { cache: { [collectionEndpoint]: collectionCache } } = useSelector(ApiCacheSelector);
	const [collection, setCollection] = React.useState<EntityType[]>((collectionCache as EntityType[]) || DEFAULT_COLLECTION);

	const initializeCollection = React.useCallback(() => {
		const controller = new AbortController();
		const signal = controller.signal;
		const onSuccess = ({ data }: { data: EntityType[] }) => {
			if (signal.aborted) {
				return;
			}

			setCollection(data);
		};
		const onFailure = () => setCollection(DEFAULT_COLLECTION);

		// Collection already exists so just return that
		if (collectionCache) {
			onSuccess({ data: collectionCache as EntityType[] });
		}
		// Otherwise, need to make API request to grab collection
		else {
			dispatch(getCollection<EntityType[]>(collectionEndpoint, {
				onSuccess,
				onFailure,
				...overrides,
			}));
		}

		return controller;
	}, [collectionCache, collectionEndpoint, dispatch, overrides]);

	return [collection, initializeCollection];
};

export default useDeferredCollection;
